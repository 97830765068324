import PageLayout from "../Layouts/PageLayout";
import Accordion from "react-bootstrap/Accordion";

export default function Faqs() {
    return (
        <PageLayout title={"FAQs"}>
            <div className={" mx-5 px-4 py-5"}>
                <AccordionContent/>
            </div>
        </PageLayout>
    )
}

function AccordionContent(){
    const accordionData = [
        {
            Header: "What is a measured building survey?",
            Body: "String Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "How much does a measured building survey cost?",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "Do I need a measured building survey?",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "How long does a measured building survey take?",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "What are the benefits of using measured building surveys?",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "How is data from a measured building survey presented?",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "How is a measured building survey carried out",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
    ];

    return (<Accordion flush className={"mb-5"}>{
        accordionData.map((item, index) => {
            return (
                <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.Header}</Accordion.Header>
                    <Accordion.Body>{item.Body}</Accordion.Body>
                </Accordion.Item>
            )
        })
    }</Accordion>)
}