import PageLayout from "../Layouts/PageLayout";

export default function PrivacyPolicy() {
    return (
        <PageLayout title={"Privacy Policy"}>
            <div className={"mx-5 px-4 py-5"} style={{textAlign: "justify"}}>
                <p>
                    Command station, this is ST 321. Code Clearance Blue. We're starting our approach. Deactivate the
                    security shield.
                </p>
                <p>
                    Hey, Jabba. Look, Jabba, I was just on my way to pay you back, but I got a little sidetracked. It's
                    not my fault. It's too late for that, Solo.
                </p>
                <p>
                    Form up. And stay alert. We could run out of space real fast. Split up and head back to the surface.
                </p>
                <p>
                    Nudd Chaa. Artoo, I have a bad feeling about this. Good morning. Bo Shuda! The message, Artoo, the
                    message.
                </p>
                <p>
                    We're coming! Come on! Come on! Oh, Artoo, hurry! My goodness! Artoo, why did you have to be so
                    brave? Well, I suppose I could hotwire this thing. I'll cover you. Yes! I said closer! Move as close
                    as you can and engage those Star Destroyers at point-blank range. At that close range, we won't last
                    long against those Star Destroyers.
                </p>
            </div>
        </PageLayout>
    )
}