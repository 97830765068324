import {useEffect, useState} from "react";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import classNames from "classnames";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Header({floating = false}) {
    function handleScroll() {
        setTransparentHeader(window.scrollY < 50);
    }

    useEffect(() => {
        if(!floating) return;

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [floating]);

    const [transparentHeader, setTransparentHeader] = useState(floating);
    const [menuOpen, setMenuOpen] = useState(false);
    const headerClasses = classNames({
        'transparent': floating && transparentHeader,
        'floating': floating
    });

    return (
        <header>
            <Menu open={menuOpen} onClose={() => setMenuOpen(false)}/>
            <Row className={"g-0 " + headerClasses}>
                <Col xs={2}>
                    <Hamburger onClick={() => setMenuOpen(true)} className={headerClasses}/>
                </Col>
                <Col xs={8}>
                    <p className={"title p-2 m-0"}>
                        <Link to={"/"} content={"WA London"}>WA London</Link>
                    </p>
                </Col>
                <Col xs={2}></Col>
            </Row>
        </header>
    )
}