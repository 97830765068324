import PageLayout from "../Layouts/PageLayout";

export default function Terms() {
    return (
        <PageLayout title={"Terms and Conditions"}>
            <div className={"mx-5 px-4 py-5"} style={{textAlign: "justify"}}>
                <p>
                    Echo Three to Echo Seven. Han, old buddy, do you read me? Loud and clear, kid. What's up? Well, I
                    finished my circle. I don't pick up any life readings. There isn't enough life on this ice cube to
                    fill
                    a space cruiser.
                </p>
                <p>
                    Hey, Your Worship, I'm only trying to help. Would you please stop calling me that? Sure, Leia. Oh,
                    you
                    make it so difficult sometimes. I do, I really do. You could be a little nicer, though. Come on,
                    admit
                    it.
                </p>
                <p>
                    Perhaps you are not as strong as the Emperor thought. Impressive...most impressive. Obi-Wan has
                    taught
                    you well. You have controlled your fear. now release your anger.
                </p>
                <p>
                    Lando, open the top hatch. Okay. Easy, Chewie. Lando? Okay, let's go.
                </p>
                <p>
                    Let's hope we don't have a burnout. See? Someday you're going to be wrong, and I hope I'm there to
                    see
                    it. Punch it! Artoo! Get her ready for takeoff. Good luck, Luke.
                </p>
            </div>
        </PageLayout>
    )
}