export default function Hamburger({onClick}) {
    return (
        <div className={"hamburger"} onClick={onClick} role="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="3rem" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_429_11066)">
                    <path d="M3 6.00092H21M3 12.0009H21M3 18.0009H21" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_429_11066">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.000915527)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}
