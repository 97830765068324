import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";

export default function MenuItem({link, text}) {
    return (
        <Link to={link} className={"menu-item"}>
            <span>{text}</span>
            <FontAwesomeIcon icon={faAngleRight} className={"float-end"}/>
        </Link>

    )
}