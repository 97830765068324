import Header from "../Components/Header";
import {Container} from "react-bootstrap";
import Footer from "../Components/Footer";

export default function PageLayout({title, style, children}) {
    return (
        <Container fluid className={"g-0 page-container"} style={style}>
            <Header/>
            <Container className="page-layout">
                {
                    title && <h1 className={"page-title"}>{title}</h1>
                }
                <section className={"page-content"}>
                {children}
                </section>
            </Container>
            <Footer/>
        </Container>
    )
}