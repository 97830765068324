import {Image} from "react-bootstrap";

export default function ImageStrip({images = []}) {
    const imageStripStyle = {
        overflowX: 'scroll',
        height: "auto",
        display: "flex",
    }

    const stripImageStyle = {
        maxHeight: "25rem",
        maxWidth: "100%"
    }

    return (
        <div className={"image-strip"} style={imageStripStyle}>
            {
                images.map((image, index) => {
                    return (
                        <Image style={stripImageStyle} src={image}/>
                    )
                })
            }
        </div>
    )
}