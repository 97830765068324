import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {Container} from "react-bootstrap";

export default function HeroPageLayout({children}) {
    return (
        <>
            <Container fluid className={"hero-page-layout"}>
                <Header floating={true}/>
                {children}
            </Container>
            <Footer/>
        </>
    );
}