import PageLayout from "../Layouts/PageLayout";
import {Button, Col, Form, Row} from "react-bootstrap";

export default function ContactUs() {
    return (
        <PageLayout title={"Contact Us"} style={{backgroundColor: "#F2F2F2"}} className={"justify-content-center"}>
            <Row className={"justify-content-center"}>
                <Col lg={8} sm={12}>
                    <Form>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email"/>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control type="text" as="textarea" rows={10}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button variant="primary" type="submit" className={"w-100 mb-5"}>
                                    Send
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </PageLayout>
    )
}