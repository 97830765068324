import PageLayout from "../Layouts/PageLayout";
import Accordion from "react-bootstrap/Accordion";
import {Col, Image, Row} from "react-bootstrap";
import BackedContainer from "../Components/BackedContainer";
import ImageStrip from "../Components/ImageStrip";

export default function Project() {
    const stripImages = [
        "images/rebournehealthfitness-16.webp",
        "images/rebournehealthfitness-104.webp",
        "images/rebournehealthfitness-33.webp",
        "images/rebournehealthfitness-114.webp",
        "images/rebournehealthfitness-120.webp",
    ];

    return (
        <PageLayout>
            <Row className={"mb-5 mt-3"}>
                <Col lg={6} sm={12} >
                    <div>
                        <Content/>
                        <AccordionContent/>
                    </div>
                </Col>
                <Col lg={6} sm={12}>
                    <BackedContainer>
                        <Image src={"images/cad2.webp"} className={"w-100"}/>
                    </BackedContainer>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <BackedContainer>
                        <ImageStrip images={stripImages}/>
                    </BackedContainer>
                </Col>
            </Row>
            <Row className={"g-0"}>
                <Col lg={6}>
                    <Image fluid src={"images/elstree-blog-main-image.webp"}/>
                </Col>
                <Col lg={6}>
                    <Image fluid src={"images/rebourne-fitness-blog-main.webp"}/>
                </Col>
            </Row>
        </PageLayout>
    );
}

function AccordionContent(){
    const accordionData = [
        {
            Header: "Materials",
            Body: "String Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
        {
            Header: "Client feedback",
            Body: "Lorems ipsums dolor sit amet con banjo, consectetur adipiscing elit string, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
        },
    ];

    const accordionItems = accordionData.map((item, index) => {
        return (
            <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{item.Header}</Accordion.Header>
                <Accordion.Body>{item.Body}</Accordion.Body>
            </Accordion.Item>
        )
    });

    return (<Accordion flush className={"mt-5"}>{accordionItems}</Accordion>)
}

function Content() {
    return (
        <>
            <b>Project Overview</b>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
            </p>
            <ul>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
                <li>Donec euismod, nisl eget</li>
            </ul>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
            </p>
            <p>
                Option - Result<br/>
                Option - Result<br/>
                Option - Result<br/>
                Option - Result<br/>
            </p>
        </>
    );
}