import MenuItem from "./MenuItem";

export default function Menu({open, onClose}) {
    const menuStyle = {}

    if (open) {
        menuStyle.left = "0";
    }

    return (
        <div style={menuStyle} className={"side-menu"}>
            <div className={"side-menu-header"}>
                <div className="text-end m-3" onClick={onClose} role="button">
                    <p>close</p>
                </div>
            </div>
            <div className={"side-menu-body"}>
                <MenuItem link={"/"} text={"Home"}/>
                <MenuItem link={"/project"} text={"Project"}/>
            </div>
        </div>
    );
}