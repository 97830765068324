import {Col, Container, Row} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons"

export default function Footer({style = {}}) {

    const menuItems1 = [
        {
            heading: "Practice",
            items: [
                {
                    to: "/about-us",
                    text: "About us"
                },
                {
                    to: "/blog",
                    text: "Blog"
                }
            ]
        }
    ];

    const menuItems3 = [
        {
            heading: "Information",
            items: [
                {
                    to: "/faq",
                    text: "FAQs"
                },
                {
                    to: "/contact-us",
                    text: "Contact us"
                }
            ]
        }
    ];

    const menuItems4 = [
        {
            heading: "Terms & Privacy",
            items: [
                {
                    to: "/terms",
                    text: "Terms & Conditions"
                },
                {
                    to: "/privacy-policy",
                    text: "Privacy Policy"
                }
            ]
        }
    ];

    const menuItems = [
        ...menuItems1,
        ...menuItems3,
        ...menuItems4,
    ]

    function MenuItems({heading, items}) {
        return (
            <Accordion flush>
                <Accordion.Item eventKey={"1"}>
                    <Accordion.Header>{heading}</Accordion.Header>
                    <Accordion.Body className={"accordion-menu"}>
                        {
                            items.map((item, index) => {
                                return (
                                    <Link to={item.to} key={index}>{item.text}</Link>
                                )
                            })
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    return (
        <footer style={style}>
            <Container fluid>
                <Row >
                {
                    menuItems.map((menuItem, index) => {
                        return (
                                <Col lg={3} sm={12} key={index}>
                                    <MenuItems heading={menuItem.heading} items={menuItem.items}/>
                                </Col>
                        )
                    })
                }
                </Row>
                <Row>
                    <Col className={"social-icons"} lg={3} sm={12}>
                        <i><FontAwesomeIcon icon={faFacebookF}/></i>
                        <i><FontAwesomeIcon icon={faTwitter}/></i>
                        <i><FontAwesomeIcon icon={faInstagram}/></i>
                        <i><FontAwesomeIcon icon={faTiktok}/></i>
                        <i><FontAwesomeIcon icon={faYoutube}/></i>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}