import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";

export default function NotFound() {
    return (
        <Container className={"not-found"}>
            <Row>
                <Col sm={12} className={"text-center"}>
                    <img src={"/images/caveman-2.png"} alt={"Caveman"} className={"w-75"}/>
                    <h1>404</h1>
                    <h3>
                        Ug. Me no find page.
                    </h3>
                    <h3>
                        Go <Link to='/'>home</Link> for now.
                    </h3>
                </Col>
            </Row>
        </Container>
    )
}