import PageLayout from "../Layouts/PageLayout";

export default function AboutUs() {
    return (
        <PageLayout title={"About Us"}>
            <div className={" mx-5 px-4 py-5"} style={{textAlign: "justify"}}>
                <p>
                    Did you hear that? They've shut down the main reactor. We'll be destroyed for sure. This is madness!
                    We're doomed! There'll be no escape for the Princess this time. What's that? Artoo! Artoo-Detoo,
                    where are you? At last! Where have you been? They're heading in this direction.
                </p>
                <p>
                    Governor Tarkin, we have an emergency alert in detention block A A-twenty-three. The princess! Put
                    all sections on alert! Obi-Wan is here. The Force is with him. If you're right, he must not be
                    allowed to escape. Escape is not his plan. I must face him alone.
                </p>
                <p>
                    I wish I'd known him. He was the best star-pilot in the galaxy, and a cunning warrior. I understand
                    you've become quite a good pilot yourself. And he was a good friend. Which reminds me. I have
                    something here for you.
                </p>
                <p>
                    I sense something...a presence I haven't felt since. Get me a scanning crew in here on the double. I
                    want every part of this ship checked! Boy, it's lucky you had these compartments. I use them for
                    smuggling. I never thought I'd be smuggling myself in them.
                </p>
                <p>
                    Well, I didn't. What the hell are you doing? Somebody has to save our skins. Into the garbage chute,
                    wise guy. Get in there you big furry oaf! I don't care what you smell! Get in there and don't worry
                    about it.
                </p>
            </div>
        </PageLayout>
    )
}