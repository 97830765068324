import HeroImage from "../Components/HeroImage";
import HeroPageLayout from "../Layouts/HeroPageLayout";
import {Col, Row} from "react-bootstrap";

export default function Home() {
    return (
        <HeroPageLayout>
            <Row>
                <HeroImage image="/images/drone-2.jpg"/>
            </Row>
            <Row>
                <Col lg={6} sm={12} className="g-0">
                        <HeroImage image="/images/controller.jpg"/>
                </Col>
                <Col lg={6} sm={12} className="g-0">
                        <HeroImage image="/images/plans.jpg"/>
                </Col>
            </Row>
        </HeroPageLayout>
    );
}