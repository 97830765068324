import Home from "./Pages/Home";
import {Route, Routes} from "react-router-dom";
import Project from "./Pages/Project";
import NotFound from "./Pages/NotFound";
import AboutUs from "./Pages/AboutUs";
import Faqs from "./Pages/Faqs";
import ContactUs from "./Pages/ContactUs";
import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/project" element={<Project/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/faq" element={<Faqs/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path='*' element={<NotFound />}/>
        </Routes>
    );
}

export default App;
