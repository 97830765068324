export default function BackedContainer({children}){
    const containerStyle = {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'var(--primary)',
        padding: '5rem 0',
    }

    return (
        <div className="image-container" style={containerStyle}>
            {children}
        </div>
    );
}